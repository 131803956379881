import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material'; // Import FormControlLabel for the label
import check from '../../assets/check.png';
import './Pricecard.css'; // Import the CSS file for animations

function PriceCard({ openModal }) {
  // State to handle the switch toggle between monthly and one-time
  const [isMonthly, setIsMonthly] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false); // New state for animation

  // Function to toggle the payment plan with animation
  const togglePaymentPlan = (event) => {
    setIsAnimating(true); // Start the fade-out animation
    setTimeout(() => {
      setIsMonthly(!isMonthly); // Toggle the payment plan after the fade-out
      setIsAnimating(false); // Start the fade-in animation
    }, 300); // Time delay matches CSS transition duration
  };

  // Function to handle opening the modal with appropriate plan
  const handleOpenModal = () => {
    const selectedPlan = isMonthly ? 'Easy Pay Monthly' : 'Pay and Save';
    openModal(selectedPlan); // Pass the plan info to the modal
  };

  return (
    <div className="w-full h-auto pb-10 xl:w-[90%] xl:h-[800px] bg-white/10 dark:bg-black/20 border border-blue-600/10 dark:border-[#F8F9FA]/20 rounded-2xl shadow-sm shadow-[#2C90C7]/20 relative transition-colors duration-300 backdrop-blur-md">
      {/* Material-UI Switch Component with Accessible Label */}
      <div className="absolute top-4 right-4 flex items-center gap-2">
        <FormControlLabel
          control={
            <Switch
              checked={isMonthly}
              onChange={togglePaymentPlan}
              color="primary"
            />
          }
          label={<span className="text-gray-900 dark:text-white">{isMonthly ? 'Easy Pay Monthly' : 'Pay and Save'}</span>}
        />
      </div>

      {/* Card Content */}
      <div className="w-full px-[26px] flex flex-col gap-4">
        <span className="w-full flex justify-center text-[28px] font-bold text-center pt-[80px] px-[84px] text-gray-900 dark:text-white">
          Basic Web Package
        </span>
        <h5 className="text-[12px] text-gray-700 dark:text-[#f8f9faa9]">Starting at:</h5>

        <div className={`price-content ${isAnimating ? 'fade-out' : 'fade-in'}`}>
          <span className="labs w-full flex justify-center text-[48px] font-bold text-gray-900 dark:text-white">
            {isMonthly ? '$150/mo' : '$3000'}
          </span>

          <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
            {isMonthly
              ? 'Perfect for businesses looking for a monthly plan. This package includes everything you need to get your website up and running.'
              : 'Perfect for businesses looking for a one-time investment. This package includes everything you need to get your website up and running with minimal ongoing costs.'}
          </p>
        </div>

        <span className="text-[14px] text-gray-900 dark:text-white">What's included:</span>

        <div className="flex gap-2 items-center">
          <img className="w-6 h-6" src={check} alt="check" />
          <span className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">Up to 5 custom-designed pages.</span>
        </div>
        <div className="flex gap-2 items-center">
          <img className="w-6 h-6" src={check} alt="check" />
          <span className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
            Mobile-friendly (responsive) design so your site looks great on all devices.
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <img className="w-6 h-6" src={check} alt="check" />
          <span className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
            Basic SEO setup to help your site show up in search results.
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <img className="w-6 h-6" src={check} alt="check" />
          <span className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
            A built-in contact form so your customers can easily reach you.
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <img className="w-6 h-6" src={check} alt="check" />
          <span className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">Social media integration to link your profiles with ease.</span>
        </div>

        {/* Buttons */}
        <div className="w-full flex flex-col gap-2 mt-4">
          <button className="bg-[#1D73B8] w-full h-[48px] rounded-full text-white" onClick={handleOpenModal}>
            {isMonthly ? 'Easy Pay Monthly ($150/mo)' : 'Pay and Save ($3000)'}
          </button>
          <span className="w-full flex justify-center text-gray-700 dark:text-[#f8f9faa9]">or</span>
          <button className="bg-transparent border border-gray-300 dark:border-[#F8F9FA]/30 w-full h-[48px] rounded-full text-gray-700 dark:text-[#f8f9faa9]">
            Get a Quote For A More Customized Website
          </button>
        </div>
      </div>
    </div>
  );
}

export default PriceCard;
