import React from 'react';
import './StepCard.css'; // Import the CSS file for styling
import group from '../../assets/group.svg';
import rocket from '../../assets/rocket.svg';
import design from '../../assets/design.svg';
import maintenance from '../../assets/maintenance.svg';
import tags from '../../assets/tags.svg';

// Map the icon names to actual image imports
const icons = {
  group: group,
  rocket: rocket,
  design: design,
  maintenance: maintenance,
  tags: tags,
};

// Export the cardInfo array
export const cardInfo = [
  {
    icon: 'group',
    step: 'Step 1: Discovery & Consultation',
    header: 'Let’s Get to Know Your Business',
    description: 'We begin by learning about your business, goals, and audience. This helps us tailor the website’s purpose, design, and functionality to your needs.',
  },
  {
    icon: 'design',
    step: 'Step 2: Custom Design & Planning',
    header: 'Bringing Your Vision to Life',
    description: 'Our team creates a custom design, incorporating your feedback to ensure it reflects your brand and offers a great user experience.',
  },
  {
    icon: 'tags',
    step: 'Step 3: Development & Build',
    header: 'Turning Design Into Reality',
    description: 'We develop the site using clean, optimized code, ensuring it’s mobile-friendly, fast, and secure, with all the features you need.',
  },
  {
    icon: 'rocket',
    step: 'Step 4: Testing & Launch',
    header: 'Getting Your Site Ready for the World',
    description: 'Before going live, we thoroughly test the site across devices and browsers, fixing any issues. Once approved, we launch!',
  },
  {
    icon: 'maintenance',
    step: 'Step 5: Ongoing Support & Maintenance',
    header: 'We’re Here for You Even After Launch',
    description: 'We offer post-launch support, regular updates, and maintenance to ensure your site remains secure, functional, and optimized.',
  },
];

// StepCard component that uses the props for dynamic content
const StepCard = ({ icon, step, header, description, index }) => {
  // Apply reverse layout for steps 2 and 4
  const isReverse = index === 1 || index === 3;

  return (
    <div className="step-card p-6 md:p-10 rounded-xl w-full h-auto bg-blue-400/10 border border-[#3AAED6]/40">
      {/* Use the passed props for dynamic content */}
      <h5 className="step-subheading text-[18px] md:text-[20px] font-bold">{header}</h5>
      <div className={`step-content flex flex-col ${isReverse ? 'md:flex-row-reverse' : 'md:flex-row'} justify-start gap-6 items-center`}>
        {/* Dynamically use the icon prop */}
        <img src={icons[icon]} alt="Step Icon" className="step-icon w-12 h-12 md:w-14 md:h-14" />
        <div className="step-details text-center md:text-left">
          <p className="step-description text-[12px] md:text-[14px]  font-extralight  text-[#333333] dark:text-[#f8f9faa9]">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepCard;
