import React, { useEffect, useRef, useState } from "react";
import icon from "../../assets/icon.png";
import Timeline from "../../components/Timeline/Timeline";
import PriceCard from "../../components/PriceCard/PriceCard";
import ResponsiveTimeline from "../../components/Timeline/ResponsiveTimeline";
import Modal from "../../components/Modal/Modal";

function Services() {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // OpenModal Function
  const openModal = (plan) => {
    setModalContent(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Object Intersection for start of animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Trigger the animation when in view
        }
      },
      { threshold: 0.1 } // 20% of the section is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="w-full h-auto text-gray-900 dark:text-[#F8F9FA] mt-12 pt-28 transition-colors duration-300 relative"
    >
      <h3
        className={`px-[30px] md:px-[126px] text-center text-[36px] ${
          isInView ? "fade-in delay-1" : "fade-out"
        }`}
      >
        Services
      </h3>

      <div className="w-full h-full px-[30px] md:px-[126px] pt-12">
        <h2
          className={`dark:text-[#F8F9FA] text-[32px] md:text-[48px] lg:w-[60%] ${
            isInView ? "fade-in delay-2" : "fade-out"
          }`}
        >
          From Concept to Launch, We’ve Got You{" "}
          <span className="labs">Covered.</span>
        </h2>
      </div>

      <div
        className={`w-full px-[30px] md:px-[126px] mt-4 pt-4 flex flex-col xl:flex-row gap-12 ${
          isInView ? "fade-in delay-3" : "fade-out"
        }`}
      >
        <div className="w-full xl:w-[50%] flex flex-col gap-4">
          <h3 className="md:text-[24px] font-bold text-gray-900 dark:text-[#F8F9FA]">
            Package and Process
          </h3>
          <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-light">
            We know that building a website can seem overwhelming, but we make
            it easy and affordable. Whether you’re a small business, a growing
            company, or an established brand, we’ve got flexible options to
            bring your website to life—no technical know-how needed! Let’s
            explore the perfect package for you.
          </p>

          <div className="flex flex-col gap-12">
            <div className="flex gap-4">
              <img
                className="w-14 h-14"
                src={icon}
                alt="Icon 1"
                loading="lazy"
              />
              <div>
                <h3 className="text-[20px] text-gray-900 dark:text-[#F8F9FA]">
                  Fast, Responsive Design
                </h3>
                <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
                  Our websites load quickly and adapt to any device, ensuring a
                  seamless experience whether your customers are on mobile,
                  tablet, or desktop.
                </p>
              </div>
            </div>

            <div className="flex gap-4">
              <img
                className="w-14 h-14"
                src={icon}
                alt="Icon 2"
                loading="lazy"
              />
              <div>
                <h3 className="text-[20px] text-gray-900 dark:text-[#F8F9FA]">
                  SEO That Works
                </h3>
                <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
                  Every website we build is optimized for search engines from
                  the ground up, giving your business the best chance of being
                  found online.
                </p>
              </div>
            </div>

            <div className="flex gap-4">
              <img
                className="w-14 h-14"
                src={icon}
                alt="Icon 3"
                loading="lazy"
              />
              <div>
                <h3 className="text-[20px] text-gray-900 dark:text-[#F8F9FA]">
                  Tailored Solutions
                </h3>
                <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
                  We understand that every business is different. That’s why we
                  take the time to craft custom websites that reflect your
                  brand's unique identity.
                </p>
              </div>
            </div>

            <div className="flex gap-4">
              <img
                className="w-14 h-14"
                src={icon}
                alt="Icon 4"
                loading="lazy"
              />
              <div>
                <h3 className="text-[20px] text-gray-900 dark:text-[#F8F9FA]">
                  Long-Term Support
                </h3>
                <p className="text-[14px] text-gray-700 dark:text-[#f8f9faa9] font-extralight">
                  Our relationship doesn’t end at launch. With ongoing
                  maintenance and updates, we make sure your website evolves
                  alongside your business needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full xl:w-[50%] flex justify-end gap-4 ">
          {/* Pass the openModal function as a prop */}
          <PriceCard openModal={openModal} />
        </div>
      </div>

      {/* Conditionally render the modal */}
      {isModalOpen && (
        <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
          <h5 className="text-[32px] labs font-semibold">{modalContent}</h5> {/* Display the selected payment plan */}
          <p className="w-[85%] text-[#333333]/70 dark:text-[#f8f9faa9] font-extralight">
            {modalContent === 'Easy Pay Monthly'
              ? 'This is the detailed description of the Easy Pay Monthly plan. You pay $150 monthly for our basic web package.'
              : 'This is the detailed description of the Pay and Save plan. You make a one-time payment of $3000 for our basic web package.'}
          </p>
        </Modal>
      )}

      <ResponsiveTimeline />
    </div>
  );
}

export default Services;
