import React, { useEffect, useRef, useState } from 'react';

function Testimonials() {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Trigger the animation when it enters the viewport
        }
      },
      { threshold: 0.2 } // 20% of the section is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="testimonials-section w-full h-auto md:h-auto text-black dark:text-[#F8F9FA] ">
      {/* Top Container - Text Content */}
      <div className={`w-full h-auto px-[30px] md:px-[126px] text-center flex flex-col gap-6 items-center pt-12 transition-opacity duration-700 ${isInView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
        <h2 className="text-[32px] md:text-[48px] px-[30px] md:px-[126px] dark:text-[#F8F9FA]">
          Our <span className="labs">Testimonials</span>
        </h2>
        <span className="text-[20px] md:text-[28px] leading-normal font-light dark:text-[#F8F9FA]">
          We're Proud to be the Trusted Web Development Partner for Businesses of All Sizes.
        </span>
        <p className="w-full lg:w-[70%] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-[14px]">
          We're proud to be the trusted web development partner for businesses of all sizes. Our clients love how we turn their visions into reality with custom, high-quality websites that are fast, responsive, and easy to maintain. From seamless user experiences to on-point SEO, we’re committed to delivering results that help businesses grow. But don’t just take our word for it—here’s what our clients have to say about working with us!
        </p>
      </div>

      {/* Bottom Container - Image and Testimonial Cards */}
      <div className={`w-full h-auto px-[30px] md:px-[126px] py-10 flex flex-wrap justify-center gap-4 transition-opacity duration-700 ${isInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        {/* Testimonial Cards */}
        {Array(4).fill(0).map((_, index) => (
          <div
            key={index}
            className={`w-[500px] h-auto border border-[#333333]/20 shadow-md dark:border-[#F8F9FA]/20 rounded-2xl p-6 testimonial-card transition-opacity duration-700 backdrop-blur-lg ${isInView ? `opacity-100 translate-y-0 delay-${index * 200}` : 'opacity-0 translate-y-10'}`}
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div>image</div>
                <div>
                  <p className="font-light dark:text-[#F8F9FA]">Name</p>
                  <p className="font-light dark:text-[#F8F9FA]/70">Company Name</p>
                </div>
              </div>
              <p className="text-[12px] text-[#333333] dark:text-[#f8f9faa9] leading-8 font-extralight">
                "Working with DevStacks Labs was an absolute game-changer for my business..."
              </p>
              <span>LOGO</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
