import React, { useEffect, useRef, useState } from 'react';
import stockwhy from "../../assets/stockwhy.avif";
import contactplaceholder from '../../assets/contactplaceholder.jpg';

function WhySection() {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Trigger the animation
        }
      },
      { threshold: 0.1 } // 10% of the section is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="why-section w-full h-auto  pt-24"
    >
      {/* Title */}
      <h2
        className={`text-[32px] md:text-[48px] px-[30px] md:px-[126px] transition-opacity dark:text-[#F8F9FA] duration-700 ${
          isInView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'
        }`}
      >
        Why <span className="labs">DevStacks Labs?</span>
      </h2>

      {/* Why Container */}
      <div
        className={`why-container w-full h-auto lg:h-screen xl:flex xl:gap-4 px-[30px] md:px-[126px] pt-4 transition-opacity duration-700 ${
          isInView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'
        }`}
      >
        {/* Left Side - Text Content */}
        <div className="left-content w-full lg:w-[50%] xl:w-[60%] h-full md:flex flex-col gap-6 pt-10">
          <p className="text-[20px] md:text-[28px] leading-normal font-light dark:text-[#F8F9FA]">
            We're More Than Developers—We're Your Partner in Online Success.
          </p>
          <p className="text-[14px]  font-extralight xl:w-[90%] dark:text-[#f8f9faa9]">
            At DevStacks Labs, we don’t just build websites—we create solutions that make your life easier. We take the hassle out of web development, delivering high-quality, secure sites that get real results. Whether it’s fast load times, optimized SEO, or a site that’s easy to manage, we’ve got you covered. We take the time to get to know your business so that every site we build is perfectly tailored to your needs and reflects what makes your brand special.
          </p>
          <p className="text-[14px] text-[#333333] dark:text-[#f8f9faa9] font-extralight xl:w-[90%]">
            We’re all about creating smooth, stress-free experiences, both for you and your customers. Our team blends tech expertise with a focus on friendly, helpful service. Whether you’re just starting out or looking to take your business to the next level, we work closely with you from start to finish to create a custom website that grows with your business. At DevStacks Labs, we’re more than just developers—we’re your partner in building an online presence that lasts.
          </p>
        </div>

        {/* Right Side - Image Content */}
        <div
          className={`right-content w-full md:w-[50%] h-auto p-10 transition-opacity duration-700 ${
            isInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'
          }`}
        >
          <img
            className="w-full h-[90%] object-cover rounded-xl"
            src={contactplaceholder}
            loading="lazy"
            alt="stock"
            width="481"
            height="420"
          />
        </div>
      </div>
    </div>
  );
}

export default WhySection;
