import React, { useEffect, useState } from 'react';
import './Modal.css'; // Import the CSS file where the animations are defined

function Modal({ isModalOpen, closeModal, children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  // Handle modal visibility and animation state
  useEffect(() => {
    if (isModalOpen) {
      setIsVisible(true);
      setIsAnimating(true); // Trigger the opening animation
    } else {
      setIsAnimating(false); // Trigger the closing animation
      // Delay hiding the modal until the animation completes
      setTimeout(() => setIsVisible(false), 300); // Matches the animation duration
    }
  }, [isModalOpen]);

  return (
    <>
      {isVisible && (
        <div className={`fixed inset-0 bg-black/50 dark:bg-black/50 bg-opacity-50 flex items-center justify-center transition-opacity z-10  backdrop-blur-0${isAnimating ? 'fade-in' : 'fade-out'}`}>
          <div className={`w-[600px] h-[400px] bg-[#f8f9faa9]/85 dark:bg-black/40 dark:backdrop-blur-lg border border-[#F8F9FA]/20 rounded-2xl relative transform ${isAnimating ? 'slide-up' : 'slide-down'}`}>
            <div className='w-full h-[70%] flex flex-col justify-center items-center gap-4 p-2 text-center '>
            {children}
            </div>
            <button
              onClick={closeModal}
              className="absolute top-5 right-5 text-black dark:text-[#f8f9faa9] text-[16px]"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
