import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll"; // Import ScrollLink from react-scroll
import "../Navbar/Navbar.css";
import logo from "../../assets/logob.png";
import ToggleDarkMode from "../DarkModeToggle/ToggleDarkMode";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav
        className={`navbar w-full h-[70px] flex items-center justify-between z-50 px-[30px] md:px-[126px] py-[36px] sticky top-0 text-[14px] ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <h4>
          DEVSTACKS <span className="labs">LABS</span>
        </h4>

        <div className="visible lg:hidden">
        <ToggleDarkMode />
        </div>
        {/* Hamburger Menu for Mobile */}
        <div className="hamburger-icon lg:hidden" onClick={toggleMenu}>
          <span className="material-icons">{isMenuOpen ? "close" : "menu"}</span>
        </div>

        {/* Desktop Menu Links */}
        <ul className={`list-none flex gap-12 lg:flex hidden items-center `}>
          <li>
            <a href="#home">
              <ScrollLink to="home" smooth={true} duration={500}>
                Home
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#about">
              <ScrollLink to="about" smooth={true} duration={500}>
                About
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#services">
              <ScrollLink to="services" smooth={true} duration={500}>
                Services
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#portfolio">
              <ScrollLink to="portfolio" smooth={true} duration={500}>
                Portfolio
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#contact">
              <ScrollLink to="contact" smooth={true} duration={500}>
                Contact
              </ScrollLink>
            </a>
          </li>
        </ul>
        <div className="hidden lg:flex ">
        <ToggleDarkMode />
        </div>
      </nav>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="#home">
              <ScrollLink to="home" smooth={true} duration={500} onClick={toggleMenu}>
                Home
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#about">
              <ScrollLink to="about" smooth={true} duration={500} onClick={toggleMenu}>
                About
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#services">
              <ScrollLink to="services" smooth={true} duration={500} onClick={toggleMenu}>
                Services
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#portfolio">
              <ScrollLink to="portfolio" smooth={true} duration={500} onClick={toggleMenu}>
                Portfolio
              </ScrollLink>
            </a>
          </li>
          <li>
            <a href="#contact">
              <ScrollLink to="contact" smooth={true} duration={500} onClick={toggleMenu}>
                Contact
              </ScrollLink>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
