import React, { useEffect, useState, useRef } from "react";
import Timeline from "../../components/Timeline/Timeline";
import customweb from "../../assets/customweb.svg";
import seo from "../../assets/seotime.svg";
import appintegration from "../../assets/appintegration.svg";
import support from "../../assets/support.svg";
import WhySection from "./WhySection";
import Testimonials from "./Testimonials";
import "./About.css"; // Import the CSS file for animations

function About() {
  const [isInView, setIsInView] = useState({
    h2: false,
    p: false,
    whatsOffered: false,
  });

  const h2Ref = useRef(null);
  const pRef = useRef(null);
  const whatsOfferedRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === h2Ref.current && entry.isIntersecting && !isInView.h2) {
            setIsInView((prev) => ({ ...prev, h2: true }));
          }
          if (entry.target === pRef.current && entry.isIntersecting && !isInView.p) {
            setIsInView((prev) => ({ ...prev, p: true }));
          }
          if (entry.target === whatsOfferedRef.current && entry.isIntersecting && !isInView.whatsOffered) {
            setIsInView((prev) => ({ ...prev, whatsOffered: true }));
          }
        });
      },
      { threshold: 0.1 }
    );

    if (h2Ref.current) observer.observe(h2Ref.current);
    if (pRef.current) observer.observe(pRef.current);
    if (whatsOfferedRef.current) observer.observe(whatsOfferedRef.current);

    return () => {
      if (h2Ref.current) observer.unobserve(h2Ref.current);
      if (pRef.current) observer.unobserve(pRef.current);
      if (whatsOfferedRef.current) observer.unobserve(whatsOfferedRef.current);
    };
  }, [isInView]);

  return (
    <div className="about-section w-full h-auto mt-12 pt-28">
      {/* Heading */}
      <h3
        className={`px-[30px] md:px-[126px] text-center text-[36px] dark:text-[#F8F9FA] ${
          isInView.h2 ? "slide-in-left delay-1" : ""
        }`}
      >
        About
      </h3>

      {/* Content Container */}
      <div
        ref={h2Ref}
        className={`w-full h-auto px-[30px] md:px-[126px] text-center flex flex-col items-center pt-12 ${
          isInView.h2 ? "slide-in-left delay-2" : ""
        }`}
      >
        <h2 className="text-[32px] md:text-[48px] dark:text-[#F8F9FA]">
          One Line of Code at a Time
        </h2>
      </div>

      <div
        ref={pRef}
        className={`w-full h-auto px-[30px] md:px-[126px] text-center flex flex-col items-center ${
          isInView.p ? "slide-in-left delay-2" : ""
        }`}
      >
        <p className="w-full lg:w-[75%] xl:w-[70%] text-[#333333] dark:text-[#f8f9faa9] pt-6 font-extralight text-[14px]">
          We’re all about creating smooth, stress-free experiences, both for you
          and your customers. Our team blends tech expertise with a focus on
          friendly, helpful service. Whether you’re just starting out or looking
          to take your business to the next level, we work closely with you from
          start to finish to create a custom website that grows with your
          business. At DevStacks Labs, we’re more than just developers—we’re
          your partner in building an online presence that lasts.
        </p>
      </div>

      {/* What's Offered Section */}
      <div
        ref={whatsOfferedRef}
        className={`w-full h-auto px-[30px] md:px-[126px] flex justify-center gap-4 items-center ${
          isInView.whatsOffered ? "slide-in-left delay-3" : ""
        }`}
      >
        <div className="w-auto lg:w-[80%] xl:w-[65%]  h-full flex flex-wrap justify-between gap-8 pt-12 ">

          <div>
            <div className="flex items-center gap-3">
              <img
                src={customweb}
                alt="Custom Web Development"
                width="84"
                height="80"
              />
              <span className="text-[24px] w-[161px] dark:text-[#F8F9FA]">
                Custom Web Development
              </span>
            </div>
            <p className="w-[380px] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-[14px]">
              We design and build websites that are sleek, responsive, and
              tailored to your brand.
            </p>
          </div>

          <div>
            <div className="flex items-center gap-3">
              <img src={seo} alt="SEO & Optimization" width="84" height="80" />
              <span className="text-[24px] w-[161px] dark:text-[#F8F9FA]">
                SEO & Optimization
              </span>
            </div>
            <p className="w-[340px] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-[14px]">
              We ensure your website is optimized for both users and search
              engines.
            </p>
          </div>

          <div>
            <div className="flex items-center gap-3">
              <img
                src={appintegration}
                alt="App Integrations"
                width="84"
                height="80"
              />
              <span className="text-[24px] w-[161px] dark:text-[#F8F9FA]">
                App Integrations
              </span>
            </div>
            <p className="w-[380px] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-[14px]">
              We connect your website with tools and apps to streamline
              operations and improve functionality.
            </p>
          </div>

          <div>
            <div className="flex items-center gap-3">
              <img src={support} alt="Ongoing Support" width="84" height="80" />
              <span className="text-[24px] w-[161px] dark:text-[#F8F9FA]">
                Ongoing Support
              </span>
            </div>
            <p className="w-[340px] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-[14px]">
              Post-launch, we provide maintenance and updates to keep your site
              secure and running smoothly.
            </p>
          </div>
        </div>
      </div>

      {/* Why DevStacks Section */}
      <div className="w-full h-auto mt-16">
        <WhySection />
      </div>

      {/* Testimonials */}
      <div className="w-full h-auto mt-16">
        <Testimonials />
      </div>
    </div>
  );
}

export default About;
