import React, { useState, useEffect } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import './ToggleDarkMode.css';

const ToggleDarkMode = () => {
  // Check if dark mode preference is stored in localStorage, otherwise default to light mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('dark-mode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('dark-mode', JSON.stringify(newMode));
      return newMode;
    });
  };

  // Apply or remove the 'dark' class from the html element
  useEffect(() => {
    const rootElement = document.documentElement;
    if (isDarkMode) {
      rootElement.classList.add('dark');
    } else {
      rootElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className="dark-mode-toggle">
      <DarkModeSwitch
        checked={isDarkMode}
        onChange={toggleDarkMode}
        size={30}
        moonColor="#F0E68C"
        sunColor="#f39c12"
      />
    </div>
  );
};

export default ToggleDarkMode;
