import React from "react";
import envelope from '../../assets/envelope.svg';
import contactplaceholder from '../../assets/contactplaceholder.jpg';

function Contact() {
  return (
    <div className="w-full h-auto text-[#333333] dark:text-[#F8F9FA] mt-12 pt-28">
      <h3 className="px-[30px] md:px-[126px] text-center text-[36px] text-[#333333] dark:text-[#F8F9FA]">
  Contact
</h3>

      
      {/* Content Container */}
      <div className="w-full h-auto px-[30px] md:px-[126px] flex flex-col xl:flex-row gap-12 mt-10 pt-12">
        
        {/* Left Side - Text Content */}
        <div className="w-full lg:w-[50%] flex flex-col gap-7">
          <h2 className="text-[32px] md:text-[48px]">Get in Touch</h2>
          <p className="w-full md:w-[485px] font-extralight text-[14px]">
            We’d love to hear from you! Whether you have a question, need more details about our services, or are ready to start a project, feel free to reach out. Let’s bring your vision to life together!
          </p>
          
          {/* Form */}
          <form className="w-full flex flex-col gap-4">
            
            {/* Name Section */}
            <div className="w-full flex flex-col relative">
              <label className="text-[#333333] dark:text-[#F8F9FA]">Name</label>
              <input
                className="w-full md:w-[485px] h-[40px] border border-gray-300 rounded-md pl-14 text-[14px]
                  bg-white dark:bg-[#333333] text-[#333333] dark:text-[#F8F9FA] 
                  focus:outline-none focus:border-[#1d73b8] focus:ring-2 focus:ring-[#1d73b8]"
                placeholder="First and Last Name"
              />
              <img className="w-6 h-6 absolute top-8 left-4" src={envelope} alt="envelope" />
            </div>
            
            {/* Email Section */}
            <div className="w-full flex flex-col relative">
              <label className="text-[#333333] dark:text-[#F8F9FA]">Email</label>
              <input
                className="w-full md:w-[485px] h-[40px] border border-gray-300 rounded-md pl-14 text-[14px]
                  bg-white dark:bg-[#333333] text-[#333333] dark:text-[#F8F9FA] 
                  focus:outline-none focus:border-[#1d73b8] focus:ring-2 focus:ring-[#1d73b8]"
                placeholder="email@email.com"
              />
              <img className="w-6 h-6 absolute top-8 left-4" src={envelope} alt="envelope" />
            </div>
            
            {/* Message Section */}
            <div className="w-full flex flex-col">
              <label className="text-[#333333] dark:text-[#F8F9FA]">Message</label>
              <textarea
                className="w-full md:w-[485px] h-[160px] border border-gray-300 rounded-md pl-6 
                  bg-white dark:bg-[#333333] text-[#333333] dark:text-[#F8F9FA] 
                  focus:outline-none focus:border-[#1d73b8] focus:ring-2 focus:ring-[#1d73b8] pt-2"
                placeholder="Greetings! My Name is ..."
              />
            </div>
            
            {/* Button */}
            <button className="w-full md:w-[485px] h-[48px] border rounded-md border-[#1D73B8]/70 
              bg-[#1D73B8] text-white dark:bg-[#1D73B8] dark:text-[#F8F9FA]">
              Get in Touch
            </button>
          </form>
        </div>
        
        {/* Right Side - Image Content */}
        <div className="w-full lg:w-[50%] h-[400px] lg:h-auto">
          <img className="w-full h-[85%] object-cover rounded-xl" src="https://images.unsplash.com/photo-1727108718939-55cffc2cf873?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw1fHx8ZW58MHx8fHx8" loading="lazy" alt="contactimage" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
