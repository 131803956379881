import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import StepCard, { cardInfo } from '../StepCards/StepCard';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

const TimelineComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screen sizes
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Detect medium screens

  return (
    <div className="timeline-container w-full pt-20">
      <div className={`timeline w-full px-[30px] md:px-[126px] flex flex-col gap-4`}>

        {/* Material-UI Timeline */}
        <Timeline position={isSmallScreen ? "right" : "alternate"}>
          {cardInfo.map((card, index) => (
            <TimelineItem key={index} className={`${isSmallScreen ? 'timeline-item-small' : ''}`}>
              {!isSmallScreen && (
                <TimelineOppositeContent>
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#f8f9faa9' : 'textSecondary',
                      fontSize: { xs: '16px', md: '24px' },
                    }}
                    variant="body2"
                  >
                    {card.step}
                  </Typography>
                </TimelineOppositeContent>
              )}

              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index < cardInfo.length - 1 && <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent>
                <StepCard
                  index={index}
                  icon={card.icon}
                  step={card.step}
                  header={card.header}
                  description={card.description}
                />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default TimelineComponent;
