import React from 'react';
import { Link as ScrollLink } from 'react-scroll'; // Import ScrollLink from react-scroll

function Footer() {
  return (
    <div className='w-full h-[200px] flex justify-center items-center border-t dark:border-[#f8f9faa9]/20'>
      {/* Container */}
      <div className='w-[80%] flex flex-col gap-4'>
        <h3 className='flex justify-center'>LOGO</h3>
        <ul className='flex justify-center text-[14px] gap-6'>
          <li>
            <ScrollLink to="home" smooth={true} duration={500} spy={true} exact="true" offset={-50} className="cursor-pointer">
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="about" smooth={true} duration={500} offset={-50} className="cursor-pointer">
              About
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="services" smooth={true} duration={500} offset={-50} className="cursor-pointer">
              Services
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="portfolio" smooth={true} duration={500} offset={-50} className="cursor-pointer">
              Portfolio
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="contact" smooth={true} duration={500} offset={-50} className="cursor-pointer">
              Contact
            </ScrollLink>
          </li>
        </ul>
        {/* Divider */}
        <div className='w-full h-[10px] border-b dark:border-[#f8f9faa9]/20'></div>
        <span className='flex justify-center text-[12px] text-[#333333] dark:text-[#f8f9faa9]'>
          DevStacksLabs All Rights Reserved 2024
        </span>
      </div>
    </div>
  );
}

export default Footer;
