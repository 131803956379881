import React from "react";
import "./Portfolio.css"; // Import the CSS for the layout

function Portfolio() {
  return (
    <div className="portfolio-section">
      {/* Header and Paragraph */}
      <div className="text-container flex flex-col items-center pt-16">
        <h3 className="px-[30px] md:px-[126px] text-center text-[36px]">
          Portfolio
        </h3>
        <p className="w-full p-3 lg:w-[100%] text-[#333333] dark:text-[#f8f9faa9] font-extralight text-center flex justify-center pt-12 text-[16px]">
        Our portfolio showcases some of the best websites we've had the opportunity to create, each designed to meet the specific goals of our clients. From modern, responsive designs to custom-built, high-performance platforms, we take pride in crafting exceptional websites. Every project reflects our commitment to quality, innovation, and attention to detail.
        </p>
      </div>

      {/* Bento Box Layout */}
      <div className="bento-container px-[30px] md:px-[126px] pt-12">
        {/* ------------------------------------------ */}
        {/* Project One */}
        {/* ------------------------------------------ */}
        <div className="bento-item w-full h-full object-cover item-full-width">
          <img
            className="w-full h-full object-cover rounded-2xl opacity-60 hover:opacity-90 cursor-pointer"
            src="https://images.unsplash.com/photo-1611926653670-e18689373857?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="project" loading="lazy"
          />
        </div>
        {/* ------------------------------------------ */}
        {/* Project Two */}
        {/* ------------------------------------------ */}
        <div className="bento-item item-half-left">
          <img
            className="w-full h-full object-cover opacity-60 rounded-2xl hover:opacity-90 cursor-pointer"
            src="https://images.unsplash.com/photo-1712820504667-8952366b02d3?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="project" loading="lazy"
          />
        </div>
        <div className="bento-stack w-full h-full">
            {/* ------------------------------------------ */}
        {/* Project Three */}
        {/* ------------------------------------------ */}
          <div className="stacked-item w-full h-full object-cover opacity-60">
            <img
              className="w-full h-full object-cover rounded-2xl hover:opacity-90 cursor-pointer"
              src="https://images.unsplash.com/photo-1665470909928-a832ebc923d1?q=80&w=2912&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="project" loading="lazy"
            />
          </div>
          {/* ------------------------------------------ */}
        {/* Project Four */}
        {/* ------------------------------------------ */}
          <div className="stacked-item w-full h-full object-cover">
            <img
              className="w-full h-full object-cover opacity-60 rounded-2xl hover:opacity-90 cursor-pointer"
              src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="project" loading="lazy"
            />
          </div>
          {/* ------------------------------------------ */}
        {/* Project Five */}
        {/* ------------------------------------------ */}
          <div className="stacked-item w-full h-full object-cover">
            <img
              className="w-full h-full object-cover opacity-60 rounded-2xl hover:opacity-90 cursor-pointer"
              src="https://images.unsplash.com/photo-1644329968124-4c68f17c21e3?q=80&w=2819&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="project" loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
