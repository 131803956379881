import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import TimelineComponent from '../Timeline/Timeline'; // The timeline component for larger screens
import StepCard, { cardInfo } from '../StepCards/StepCard'; // Import StepCard and cardInfo for smaller screens

const ResponsiveTimeline = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Detect small screen sizes

  return (
    <div className="w-full h-auto mt-12 pt-28">
      {/* Conditional rendering based on screen size */}
      <div className='timeline w-full px-[30px] md:px-[126px] flex flex-col gap-4'>

      <h2 className={`text-[32px] md:text-[48px] lg:text-[64px]`} variant="h2" align="center" gutterBottom>
          Our Process: Building Your Website, <span className="labs">Step-by-Step</span>
        </h2>
      </div>
      {isSmallScreen ? (
        <div className="stacked-cards-container w-full px-[30px] md:px-[126px] flex flex-col gap-6">
          {cardInfo.map((card, index) => (
            <div key={index} className="w-full">
              <StepCard
                icon={card.icon}
                step={card.step}
                header={card.header}
                description={card.description}
                index={index}  
              />
            </div>
          ))}
        </div>
      ) : (
        <TimelineComponent />
      )}
    </div>
  );
};

export default ResponsiveTimeline;
