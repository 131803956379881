import React, { useEffect, useState } from "react";
import "../../pages/HomePage/HomePage.css";
import Navbar from "../../components/Navbar/Navbar";
import devices from "../../assets/devices5.avif";
import About from "../About/About";
import Services from "../Services/Services";
import Contact from "../Contact/Contact";
import Portfolio from "../Portfolio/Porftolio";
import { Element } from "react-scroll"; // Import Element

function HomePage() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Update the mouse position state based on mouse movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY + window.scrollY,  // Track both mouse position and scroll position
      });
    };

    // Add event listener for mouse movement
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="relative w-full h-auto">
      {/* Blue blur background */}
      <div
        className="blue-blur"
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
        }}
      ></div>


      <div className="w-full h-auto px-[30px] md:px-[126px] py-10">
        {/* Top - Content */}
        <div className="w-full text-center flex flex-col gap-4 md:gap-7 items-center pt-8">
          <h1 className="w-[90%] xl:w-[90%] text-[32px] md:text-[48px] lg:text-[64px] dark:text-[#F8F9FA]">
            At DevStacks Labs, We Code the Future of <span className="labs">Your Brand.</span>
          </h1>
          <p className="sub-header text-[20px]  md:text-[28px] lg:text-[28px] dark:text-[#F8F9FA] font-light">
            Custom-Built Websites Designed to Evolve with Your Business
          </p>
          <p className="devstacks-description w-[100%] lg:w-[65%]   text-[14px] md:text-[14px] dark:text-[#f8f9faa9] font-extralight">
            At DevStacks Labs, we craft sleek, high-performance websites without the headaches. Our mission? Empower small businesses and startups to launch and scale their online presence effortlessly. Whether you're starting fresh or leveling up, we’re here to make your digital journey smooth and impactful.
          </p>

          {/* Button Container */}
          <div className="w-full md:flex md:justify-center gap-3 py-4">
            <button className="w-[253px] h-[48px] text-white border rounded-full bg-[#216aa7] border-[#216aa7]/60 shadow-sm shadow-[#216aa7]">
              Let’s Bring Your Idea to Life
            </button>
            <button className="about-us-button w-[253px] h-[48px]">About Us</button>
          </div>

          {/* Image Container */}
          <div className="w-full flex justify-center">
            <img
              className="devices"
              src={devices}
              alt="device"
              width="1200"
              height="800"
            />
          </div>
        </div>
      </div>

      {/* Use the Element component to wrap sections */}
      <Element name="about">
        <About />
      </Element>

      <Element name="services">
        <Services />
      </Element>

      <Element name="portfolio">
        <Portfolio />
      </Element>

      <Element name="contact">
        <Contact />
      </Element>
    </div>
  );
}

export default HomePage;
